import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import AppRoutes from "routes/AppRoutes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from "store/store";
import { FullPageLoadingProvider } from "provider/FullPageLoadingProvider";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <FullPageLoadingProvider>
                  <BrowserRouter>
                      <AppRoutes />
                  </BrowserRouter>
              </FullPageLoadingProvider>
          </PersistGate>

      </Provider>
    </ChakraProvider>
  );
}

export default App;
