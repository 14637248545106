import { combineReducers, configureStore } from "@reduxjs/toolkit";
import localStorageMiddleware from "store/middlewares/local-storage";
import api from "api/api";
import { STORE_MODULES } from "shared/constants/store-constants";
import { authFeatureReducer } from "features/Auth/store/auth-feature-reducer";
import { chatFeatureReducer } from "features/Chat/store/chat-feature-reducer";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [STORE_MODULES.AUTH]: authFeatureReducer,
  [STORE_MODULES.CHAT]: chatFeatureReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      api.middleware,
      localStorageMiddleware,
    ]),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
